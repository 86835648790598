@import "/src/styles/common";

body {
  background-color: #fcfdff;
  font-family: "Verdana", Verdana, sans-serif;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

// .react-toggle--focus .react-toggle-thumb {
//   -webkit-box-shadow: 0px 0px 3px 2px #ccc;
//   -moz-box-shadow: 0px 0px 3px 2px #ccc;
//   box-shadow: 0px 0px 2px 3px #ccc;
// }

// .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
//   -webkit-box-shadow: 0px 0px 5px 5px #ccc;
//   -moz-box-shadow: 0px 0px 5px 5px #ccc;
//   box-shadow: 0px 0px 5px 5px #ccc;
// }

/*--- flone preloader ---*/

.flone-preloader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999;
}

.flone-preloader {
  width: 3rem;
  height: 3rem;
  display: inline-block;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.flone-preloader span {
  position: absolute;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background: $theme-color;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.flone-preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
/*--- end of flone preloader ---*/

label {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #343a40;
  margin-bottom: 0.25rem;
}

input.form-control {
  border-radius: 2px !important;
  font-size: 13px !important;
  padding: 8px !important;
}

textarea.form-control {
  border-radius: 2px !important;
  font-size: 13px !important;
  padding: 8px !important;
}

select {
  border-radius: 2px !important;
  font-size: 13px !important;
}

button,
.btn {
  border-radius: 2px !important;
  font-size: 13px !important;
  margin-bottom: 0.5rem;
  svg {
    margin-top: -4px;
  }
}

.top-loader {
  display: none;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 125px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  background: #343a40;
  color: white;
  font-size: 12px;
  padding: 3px 0;
  z-index: 1000000;
}

.loading-indicator {
  .top-loader {
    display: block;
  }
  .whole-content {
    pointer-events: none;
    opacity: 0.6;
  }
}

a {
  font-size: 13px;
  font-weight: 600;

  &:hover {
    color: #000000;
  }
}

.upload-thumb {
  width: 13rem;
  height: 13rem;
  margin-right: 5px;
  margin-bottom: 5px;
  object-fit: scale-down;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #ccc;

  &.video {
    width: 15rem;
  }
}

.upload-thumb-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .delete-thumb {
    z-index: 1;
    position: absolute;
    margin: 2px 0 0 2px;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    background-color: #e25555;
    vertical-align: middle;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #db2222;
    }
  }
  .select-thumb {
    z-index: 1;
    position: absolute;
    margin: 2px 0 0 2px;
    border-radius: 25px;
    height: 3rem;
    width: 3rem;
    background-color: #fff;
    vertical-align: middle;
    text-align: center;
    color: #222;
    cursor: pointer;

    &:hover {
      background-color: green;
    }
  }
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(117, 117, 117);
  outline: 1px solid #ccc;
}

.form-control:focus {
  border-color: #3a3a3a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #44444440;
}

*::-moz-selection {
  background: $little-dark-color;
  color: #ffffff;
}
*::selection {
  background: $little-dark-color;
  color: #ffffff;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #222;
}

.fs-tiny {
  font-size: 10px;
}

.fs-little {
  font-size: 12px;
}

.like-btn {
  cursor: pointer;
  &:hover {
    color: $hover-color;
  }
}

.ct-toast {
  .ct-text {
    font-size: 12px !important;
  }
}

table {
  font-size: 13px;
  tr {
    cursor: pointer;
  }
  td,
  th {
    vertical-align: middle;
  }
}

p {
  font-size: 10px !important;
}

.bottom-shadow {
  box-shadow: 0 0 10px -2px #ccc;
}

.nav-tabs .nav-link {
  color: #d79a09;
}

.part-section {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: inset 0 0 10px -3px #ccc;
  margin-bottom: 1rem;
}
