.image-logo {
  height: 150px;
  width: 150px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #ccc;
  object-fit: scale-down;
  background: #fff;
}

.image-input-contianer {
  height: 150px;
  width: 150px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
