.react-tel-input .form-control {
  width: 100% !important;
  padding: 0.47rem 0.75rem 0.47rem 60px !important;
  border: 1px solid #ced4da;
  border-radius: 2px !important;
  font-weight: 500 !important;

  &:focus {
    border: solid 1px #9c9c9b !important;
    box-shadow: 0 0 0 0.2rem #44444440 !important;
  }
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border: solid 1px #9c9c9b !important;
  box-shadow: 0 0 0 0.2rem #44444440 !important;
}

// @media only screen and (min-width: 1200px) and (max-width: 1350px) {
//   .react-tel-input .form-control {
//     padding: 0.4rem 0.75rem 0.4rem 60px !important;
//   }
// }
