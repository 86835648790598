@import "/src/styles/common";

.side-bar {
  background-color: $theme-color;
  height: calc(100vh - 2.8rem);
  overflow: auto;
  width: 3rem;

  .side-menu-item {
    color: white;
    font-size: 12px;
    padding: 0.8rem;
    cursor: pointer;
    text-align: center;

    .side-menu-text {
      display: none;
    }

    svg {
      font-size: 20px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: $hover-color;
      box-shadow: 0 0 1px $hover-color;
    }

    &.active {
      color: $hover-color;
    }
  }
}

@media (min-width: 769px) {
  body.side-bar-visible {
    .side-bar {
      width: 15rem;

      .side-menu-item {
        text-align: left;
        .side-menu-text {
          display: inline;
        }
      }
    }
  }

  body.side-bar-invisible {
    .side-bar {
      width: 3rem;
      .side-menu-item {
        text-align: center;
        .side-menu-text {
          display: none;
        }
      }
    }
  }
}
