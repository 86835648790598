@import "/src/styles/common";

.check-list {
  label {
    font-size: 13px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    text-transform: none;

    input[type="checkbox"] {
      margin-right: 0.5rem;
      &:checked {
        background-color: black;
      }
    }
  }
}
