.file-upload {
  background-color: #e6e6e6;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  border: 1px dashed #b1b1b1;
  outline: none;
  padding: 1rem;
  border-radius: 3px;
  cursor: pointer;

  .upload-icon {
    width: 100%;
    text-align: center;
    color: rgb(155, 151, 151);
  }

  p {
    margin: 0 auto;
    font-size: 12px !important;
  }
}
