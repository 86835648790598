.app-page {
  position: relative;
  background-color: #f4f5f7;

  .page-content {
    position: absolute;
    right: 0;
    top: 2.8rem;
    left: 3rem;
    bottom: 0;
    margin: 1rem;
    padding: 1rem;
    overflow: hidden;
    background-color: #fff;

    .content-area {
      height: 100%;
      overflow: auto;
      padding-bottom: 2rem;
    }
  }
}

@media (min-width: 769px) {
  body.side-bar-visible {
    .page-content {
      left: 15rem;
    }
  }

  body.side-bar-invisible {
    .page-content {
      left: 3rem;
    }
  }
}
