@import "/src/styles/common";

.header-bar {
  background-color: $theme-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.8rem;
  color: white;
  padding: 0 0.5rem;
  border-bottom: 1px solid white;

  .side-button {
    transition-duration: 80ms;
    display: none;
    @media (min-width: 769px) {
      display: block;
    }
    &:active {
      height: 20px;
    }
  }
}
