.app-dollar-input {
  position: relative;

  span {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
